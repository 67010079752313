<script>
import api from "@/api";
import tool, { treearr } from "@/tool";
import base from "@/templates/list";
import MyDialog from "./dialog";
import MyDialog2 from "./dialog-upload";
import MyDialog3 from "./dialog-info";
export default {
  directives: {},
  filters: {},
  components: {
    MyDialog,
    MyDialog2,
    MyDialog3,
  },
  extends: base,
  props: {},
  data() {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        add: { show: true, title: "global.add" },
        import: { show: true, title: "global.import" },
      },
      showList: [
        {
          copName: "el-input",
          placeholder: "list.供应商名称/联系人",
          key: "supplierName",
          name: "",
          attrs: ["placeholder"],
        },
      ],
      formInline: {
        query: "",
        status: "",
        name: "",
        roleId: "",
        orgId: "",
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1,
        },
      },
      tableTile: [
        {
          key: "1",
          columnLabel: "list.供应商名称",
          prop: "supplierName",
          width: "",
        },
        {
          key: "2",
          columnLabel: "list.联系人",
          prop: "supplierContact",
          width: "",
        },
        {
          key: "3",
          columnLabel: "list.联系人邮箱",
          prop: "supplierContactEmail",
          width: "",
        },
        {
          key: "4",
          columnLabel: "list.联系人电话",
          prop: "supplierContactPhone",
          width: "",
        },
        {
          key: "5",
          columnLabel: "list.创建人",
          prop: "createUserName",
          width: "",
        },
        {
          key: "6",
          columnLabel: "list.创建日期",
          prop: "createTime",
          width: "",
        },
        {
          key: "7",
          columnLabel: "list.备注",
          prop: "remark",
          width: "",
        },
      ],
    };
  },
  created() {
    // api.organization.queryOrgTree({}).then(res => {
    //   this.showList[3].cascaderOptions = treearr(res.data)
    // })
    // api.auth.getAuthRoleAllRole().then(res => {
    //   this.showList[2].options = res.data.map(item => ({ ...item, label: item.roleName, value: item.id }))
    // })
  },
  methods: {
    init() {
      this.toPage(1);
    },
    toPage(page) {
      this.formInline.page.currentPage = page;
      this.onSubmit();
    },
    onSubmit() {
      this.loading = true;
      api.auth
        .querySupplierPage({
          page: this.formInline.page.currentPage,
          size: this.formInline.page.size,
          queryParam: {
            ...this.formInline,
          },
        })
        .then((res) => {
          this.tableData = res.data.records;
          this.formInline.page.total = res.data.total;
          this.loading = false;
        });
    },

    // 新增
    add() {
      this.$refs.myDialog.add();
    },
    importClick() {
      console.log(this.$refs.myDialog2);
      this.$refs.myDialog2.upload();
    },

    // 删除
    async remove(id) {
      await this.$confirm(this.$t("global.removeTooltip"), {
        type: "warning",
      });
      await api.auth.deleteSupplierById({
        id,
      });
      this.toPage(1);
    },

    // 编辑
    async edit(id) {
      const res = await api.auth.findSupplierById({ id });
      this.$refs.myDialog.edit({
        ...(res.data || {}),
      });
    },

    // 详情
    async view(id) {
      const res = await api.auth.findSupplierById({ id });
      this.$refs.myDialog3.view({
        ...(res.data || {}),
      });
    },

    // 启用
    async enable(id) {
      await this.$confirm(this.$t("global.enableTooltip"), { type: "warning" });
      await api.auth.postAuthUpdateUserStatus({ id: id, status: 1 });
      this.$message.success(this.$t("global.handleOk"));
      this.toPage(1);
    },

    // 停用
    async disable(id) {
      await this.$confirm(this.$t("global.disableTooltip"), {
        type: "warning",
      });
      await api.auth.postAuthUpdateUserStatus({ id: id, status: 0 });
      this.$message.success(this.$t("global.handleOk"));
      this.toPage(1);
    },
  },
};
</script>
